<template>
  <div class="row_contain">
    <van-loading size="24px" v-if="loadStart">加载中...</van-loading>
    <div class="shoppingRow" v-else>
      <van-empty description="暂无数据" v-if="list.data.length == 0" />
      <div v-else>
        <van-card
          v-for="(item, key) in list.data"
          :key="key"
          :num="'库存' + item.user_stock"
          currency="￥"
          :price="item.price"
          :desc="item.des"
          :title="item.title"
          :thumb="item.pic"
        >
          <template #tags>
            <van-tag plain type="danger">{{ item.tag_name }}</van-tag>
          </template>
          <template #footer>
            <div class="footerBtn">
              <van-stepper
                v-model="item.num"
                :name="item.carts_id"
                integer
                :min="item.user_stock > 0 ? '1' : item.user_stock"
                :max="item.user_stock"
                @change="onChange"
              />
              <button
                class="van-button van-button--default van-button--normal btnde"
                :data-id="item.carts_id"
                @click="dele"
              >
                <div class="van-button__content">
                  <span class="van-button__text">删除</span>
                </div>
              </button>
            </div>
          </template>
        </van-card>
      </div>
    </div>
    <van-submit-bar
      :price="list.total_price * 100"
      button-text="提交订单"
      @submit="onSubmit"
      :disabled="list.data.length == 0"
    >
    </van-submit-bar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loadStart: true,
      disabled: false,
      value: [],
      list: {
        data: [],
        total_price: 0
      }
    }
  },
  created () {
    this.load();
  },
  methods: {
    async onChange (val, { name }) {
      if (val != '') {
        this.$Toast.loading({ forbidClick: true });
        let res = await this.$http.get('cart/edit_cart', {
          params: {
            carts_id: name,
            num: val
          }
        })
        if (res.data.error_code == 0) {
          this.$Toast.clear();
          this.load();
          this.$store.dispatch('CountAsync');
          this.$Toast(res.data.error_msg)
        }
      }
    },
    async load () {
      let res = await this.$http.get('cart/get_cart_list', {
        params: {
          user_id: this.$store.state.login.userInfo.user_id
        }
      })
      if (res.data.data) {
        this.loadStart = false;
        this.list = res.data;
        res.data.data.forEach(item => {
          this.value.push(item.carts_id);
        });
      }
    },
    dele (ev) {

      this.$Dialog.confirm({
        title: '删除提示',
        message: '确认是否将该商品从购物车移除!',
      })
        .then(() => {
          this.$Toast.loading({ forbidClick: true });
          this.$http.get('cart/delete_cart', {
            params: {
              carts_id: ev.target.dataset.id
            }
          }).then(res => {
            this.$Toast.clear();
            this.load();
            this.$store.dispatch('CountAsync');
            this.$Toast(res.data.error_msg)
          })

        })
        .catch(() => {
          // on cancel
        });


    },
    onSubmit () {
      let cartsId = [...new Set(this.value)]


      this.$router.push('/orderList/' + cartsId.join(',') + '/1')
    }
  }
}
</script>

<style lang="less" scoped>
.btnde {
  color: white;
  background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));
  border: 0px;
}
.van-submit-bar {
  position: absolute;
}

.van-card__thumb {
  width: 120px;
  height: 120px;
}
.van-card__content {
  padding-right: 10px;
}
.van-card__footer {
  padding: 5px;
  border-top: 1px dashed #c9c9c9;
}
.van-card {
  background: #fff;
  margin-bottom: 10px;
}
.footerBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
